<template>
	<div class="module_main">
		<div class="content module7">
			<ul class="display">
				<li
					class="rebateSubsidy_li flex-center column"
					v-for="(item, index) in rebateSubsidy"
					:key="index"
				>
					<img
						:src="
							item.taxRebateSubsidyImgUrl
								? item.taxRebateSubsidyImgUrl
								: item.enterpriseSubsidyImgUrl
						"
						alt=""
					/>
					<div class="name">
						{{
							item.taxRebateSubsidyName
								? item.taxRebateSubsidyName
								: item.enterpriseSubsidyName
						}}
					</div>
					<div class="text">
						{{
							item.taxRebateSubsidyIntroduce
								? item.taxRebateSubsidyIntroduce
								: item.enterpriseSubsidyIntroduce
						}}
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		rebateSubsidy: {},
	},
	data() {
		return {
			list: [{}, {}, {}],
		}
	},
	mounted() {},
	methods: {},
}
</script>
<style lang="scss" scoped>
.module_main {
	padding-top: 30px;
	.module7 {
		ul {
			.rebateSubsidy_li:last-child {
				border-right: none !important;
			}
			.rebateSubsidy_li {
				width: 315px;
				height: 246px;
				border-right: 1px solid #dddddd;
				img {
					width: 110px;
					height: 110px;
					margin-top: 10px;
				}
				.name {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 20px;
					margin-top: 28px;
					margin-bottom: 14px;
				}
				.text {
					width: 208px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
					text-align: center;
				}
			}
		}
	}
}
</style>