<template>
	<div class="module_main">
		<div class="content module9">
			<div class="recruitWorkers">
				<div
					class="recruitWorkers_list flex"
					v-for="(item, index) in homeData"
					:key="index"
				>
					<div class="LeftSide">
						<div class="label display nowrap">{{ item.label }}</div>
					</div>
					<div class="secondLevel flex flex-wrap">
						<div
							class="secondLevel_list display pointer"
							:class="
								activeIndex == index && activeIndexSon == indexs ? 'active' : ''
							"
							v-for="(items, indexs) in item.children"
							:key="indexs"
							@click="changeBtn(index, indexs)"
						>
							{{ items.label }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
	},
	data() {
		return {
			activeIndex: null,
			activeIndexSon: null,
			list: [
				{
					id: 1,
					label: '特种作业',
					secondLevel: [
						{
							label: '低压电工',
						},
						{
							label: '焊工',
						},
						{
							label: '高处安装',
						},
						{
							label: '拆除作业 ',
						},
						{
							label: '一级造价工程师',
						},
					],
				},
				{
					id: 2,
					label: '施工现场专业人员',
					secondLevel: [
						{
							label: '施工员',
						},
						{
							label: '质量员',
						},
						{
							label: '安全员',
						},
						{
							label: '材料员 ',
						},
					],
				},
				{
					id: 1,
					label: '特种作业',
					secondLevel: [
						{
							label: '低压电工',
						},
						{
							label: '焊工',
						},
						{
							label: '高处安装',
						},
						{
							label: '拆除作业 ',
						},
						{
							label: '一级造价工程师',
						},
					],
				},
				{
					id: 2,
					label: '施工现场专业人员',
					secondLevel: [
						{
							label: '施工员',
						},
						{
							label: '质量员',
						},
						{
							label: '安全员',
						},
						{
							label: '材料员 ',
						},
					],
				},
				{
					id: 1,
					label: '特种作业',
					secondLevel: [
						{
							label: '低压电工',
						},
						{
							label: '焊工',
						},
						{
							label: '高处安装',
						},
						{
							label: '拆除作业 ',
						},
						{
							label: '一级造价工程师',
						},
					],
				},
				{
					id: 2,
					label: '施工现场专业人员',
					secondLevel: [
						{
							label: '施工员',
						},
						{
							label: '质量员',
						},
						{
							label: '安全员',
						},
						{
							label: '材料员 ',
						},
					],
				},
			],
		}
	},
	mounted() {},
	methods: {
		changeBtn(index, indexs) {
			this.activeIndex = index
			this.activeIndexSon = indexs
		},
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	padding-top: 10px;
	.module9 {
		.recruitWorkers {
			.recruitWorkers_list {
				margin-bottom: 15px;
				.LeftSide {
					min-width: 204px;
					position: relative;
					.label {
						min-width: 140px;
						height: 54px;
						background: #003c7e;
						padding: 0px 34px;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
				.secondLevel {
					margin-left: 24px;
					.secondLevel_list {
						height: 54px;
						border: 1px solid #dddddd;
						padding: 0px 34px;
						margin-right: 15px;
						margin-bottom: 15px;
					}
					.active {
						border: 1px solid #003c7e;
						color: #003c7e !important;
					}
				}
			}
		}
	}
}
</style>