<template>
	<div class="module_div">
		<div class="content module2">
			<ul class="flex display">
				<li
					class="list display column"
					v-for="(item, index) in homeData"
					:key="index"
				>
					<img
						class="icon_img"
						:src="item.vocationalQualificationImgUrl"
						alt=""
					/>
					<div class="name">{{ item.vocationalQualificationName }}</div>
					<div class="text">{{ item.vocationalQualificationIntroduce }}</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
	},
	data() {
		return {
			list: [{}, {}, {}, {}],
		}
	},
}
</script>
<style lang="scss" scoped>
.module_div {
	padding-top: 14px;
	.module2 {
		ul {
			.list {
				width: 208px;
				margin: 0px 20px;
				img {
					width: 162px;
					height: 172px;
				}
				.name {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					margin-top: 28px;
					line-height: 20px;
				}
				.text {
					width: 208px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
					text-align: center;
					margin-top: 15px;
				}
			}
		}
	}
}
</style>