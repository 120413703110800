<template>
	<div class="module_main">
		<div class="content module5">
			<div class="classification display">
				<div
					class="classification_list display"
					v-for="(item, index) in homeData"
					:key="index"
				>
					{{ item.trainingJobName }}
				</div>
			</div>
			<div class="mian_div display">
				<img class="icon_img" :src="imgUrl" alt="" />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
		imgUrl: {},
	},
	data() {
		return {
			activeIndex: 0,
			list: [{}, {}, {}, {}, {}, {}],
		}
	},
	mounted() {},
	methods: {
		// changeBtn(item, index) {
		// 	this.activeIndex = index
		// 	this.imgUrl = item
		// },
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	.module5 {
		.classification {
			.active {
				background: #003c7e !important;
				color: #ffffff !important;
			}
			.classification_list {
				height: 40px;
				background: #ffffff;
				border: 1px solid #dddddd;
				border-radius: 5px;
				padding: 0px 25px;
				margin: 0px 11px;
			}
		}
		.icon_img {
			width: 1000px;
			height: 320px;
			margin-top: 12px;
		}
	}
}
</style>