<template>
	<div class="module_main">
		<div class="content module2">
			<img :src="homeData" alt="" />
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
	},
	data() {
		return {}
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	.module2 {
		img {
			width: 1200px;
			height: 400px;
			margin-top: -10px;
		}
	}
}
</style>