<template>
    <div class="home_index">
        <Banner :adCategory="1"></Banner>
        <div class="module1 service_div personalService">
            <div class="content">
                <div class="title display">
                    <div class="orange_div">个人</div>
                    <div class="black_div">服务</div>
                </div>
                <div class="serviceClassification">
                    <ul class="Around">
                        <li class="list display pointer column" v-for="(item, index) in $store.state.personData"
                            :key="index" @click="changeBtn1(item.menuName, index)">
                            <img class="icon_img" :src="item.homeIconImgUrl" alt="" />
                            <div class="name" :class="activeIndex1 == index ? 'personalServiceActive' : ''">
                                {{ item.menuName }}
                            </div>
                            <div class="line" v-if="activeIndex1 == index"></div>
                        </li>
                    </ul>
                    <div class="main">
                        <Module1 v-if="activeIndex1 == 0 || activeIndex1 == 7" :activeIndex="activeIndex1" :homeData="
                            activeIndex1 == 0
                                ? homeData.newHomeSpecialOperations
                                : homeData.newHomeSkillLevels
                        "></Module1>
                        <Module2 v-if="activeIndex1 == 1" :homeData="homeData.newHomeVocationalQualifications"></Module2>
                        <Module3 v-if="activeIndex1 == 2 || activeIndex1 == 6" :activeIndexNum="activeIndex1" :homeData="
                            activeIndex1 == 2
                                ? homeData.newHomeSkillTrainings
                                : homeData.deepHouseholdCategoryEntrys
                        "></Module3>
                        <Module4 v-if="activeIndex1 == 3" :homeData="homeData.educationFourWaysS"></Module4>
                        <Module5 v-if="activeIndex1 == 4" :homeData="homeData.jobSalutationWayGets"></Module5>
                        <Module6 v-if="activeIndex1 == 5" :homeData="homeData.otherTrainingCourseOverviews"></Module6>
                        <Module7 v-if="activeIndex1 == 8" :list="homeData.labourServiceInformationCourseVOS"></Module7>
                        <Module8 v-if="activeIndex1 == 9" :list="homeData.civilServantExaminationRequirements"></Module8>
                        <Module9 v-if="activeIndex1 == 10" :list="homeData.automatedPersonnelAutomationRevolutions">
                        </Module9>
                    </div>
                    <div class="LearnMoreBtn display pointer" @click="LearnMore1">
                        点击了解更多
                    </div>
                </div>
            </div>
        </div>
        <div class="module2 service_div personalService">
            <div class="content">
                <div class="title display">
                    <div class="orange_div">企业</div>
                    <div class="black_div">服务</div>
                </div>
                <div class="serviceClassification">
                    <ul class="Around">
                        <li class="list display pointer column" v-for="(item, index) in $store.state.companyData"
                            :key="index" @click="changeBtn2(item.menuName, index)">
                            <img class="icon_img" :src="item.homeIconImgUrl" alt="" />
                            <div class="name" :class="activeIndex2 == index ? 'personalServiceActive' : ''">
                                {{ item.menuName }}
                            </div>
                            <div class="line" v-if="activeIndex2 == index"></div>
                        </li>
                    </ul>
                    <div class="main">
                        <Module1S v-if="activeIndex2 == 0" :imgUrl="
                            homeData.highSalaryCompanyHighTechnologyS[0]
                                .highTechnologyImgUrl
                        " :dataList="homeData.highSalaryCompanyHighTechnologyS"></Module1S>
                        <Module2S v-if="activeIndex2 == 1" :homeData="homeData.buildingQualificationImgUrl"></Module2S>
                        <Module3S v-if="activeIndex2 == 2" :homeData="homeData.skillRatingWhyEvaluationOrganizations">
                        </Module3S>
                        <Module4S v-if="activeIndex2 == 3" :homeData="homeData.newHomeSafetyTrainings"></Module4S>
                        <Module5S v-if="activeIndex2 == 4" :homeData="homeData.industrialWorkerTrainingJobs"
                            :imgUrl="homeData.workerTrainingImgUrl"></Module5S>
                        <Module6S v-if="activeIndex2 == 5" :homeData="homeData.systemCertificationIsoThreeCertifications"
                            :homeDataS="
                                homeData.systemCertificationPopularSystemCertifications
                            "></Module6S>
                        <Module7S v-if="activeIndex2 == 6" :rebateSubsidy="homeData.newHomeEnterpriseSubsidys"></Module7S>
                        <Module9S v-if="activeIndex2 == 8" :homeData="homeData.laborDispatchRecruitmentTree"></Module9S>
                    </div>
                    <div class="LearnMoreBtn display pointer" @click="LearnMore2">
                        点击了解更多
                    </div>
                </div>
            </div>
            <div class="module8S_div" v-if="activeIndex2 == 7">
                <Module8S :dataList="homeData.siteLeaseCourses.list" :imgList="homeData.siteLeaseCourses.list[0].images">
                </Module8S>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
import Module1 from './components/personalService/module1'
import Module2 from './components/personalService/module2'
import Module3 from './components/personalService/module3'
import Module4 from './components/personalService/module4'
import Module5 from './components/personalService/module5'
import Module6 from './components/personalService/module6'
import Module7 from './components/personalService/module7'
import Module8 from './components/personalService/module8'
import Module9 from './components/personalService/module9'
import Module1S from './components/enterpriseService/module1'
import Module2S from './components/enterpriseService/module2'
import Module3S from './components/enterpriseService/module3'
import Module4S from './components/enterpriseService/module4'
import Module5S from './components/enterpriseService/module5'
import Module6S from './components/enterpriseService/module6'
import Module7S from './components/enterpriseService/module7'
import Module8S from './components/enterpriseService/module8'
import Module9S from './components/enterpriseService/module9'

export default {
    name: 'home/index',
    components: {
        Banner,
        Module1,
        Module2,
        Module3,
        Module4,
        Module5,
        Module6,
        Module7,
        Module8,
        Module9,
        Module1S,
        Module2S,
        Module3S,
        Module4S,
        Module5S,
        Module6S,
        Module7S,
        Module8S,
        Module9S,
    },
    data() {
        return {
            activeIndex1: 0,
            activeIndex2: 0,
            homeData: {},
            classificationNameA: '特种作业',
            classificationNameB: '高新企业认定申报',
        }
    },
    mounted() {
        this.init()
        window.addEventListener('scroll', this.handleScroll)
    },
    computed: {},

    methods: {
        //获取初始化数据
        init() {
            this.api.newHomeApi().then((res) => {
                this.homeData = res.data
                this.homeData.educationFourWaysS.forEach((item) => {
                    item.educationWhyIntroduce = item.educationWhyIntroduce.split(';')
                })
                this.homeData.labourServiceInformationCourseVOS.forEach((item) => {
                    this.$set(item, 'isMOre', false)
                })
                this.homeData.siteLeaseCourses.list.forEach((item) => {
                    item.images = this.imgData(item.images, 3)
                })
            })
        },
        //个人服务切换分类
        changeBtn1(item, index) {
            this.classificationNameA = item
            this.activeIndex1 = index
        },
        //企业服务切换分类
        changeBtn2(item, index) {
            this.classificationNameB = item
            this.activeIndex2 = index
        },
        //格式化轮播图数据
        imgData(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return (dataList = [...newDataList])
        },
        //个人服务了解更多
        LearnMore1() {
            let url = ''

            if (this.classificationNameA == '特种作业') {
                url = '/homes/specialOperations'

            } else if (this.classificationNameA == '执业资格') {
                url = '/homes/registered'

            } else if (this.classificationNameA == '技能培训') {
                url = '/homes/constructionSite'

            } else if (this.classificationNameA == '学历提升') {
                url = '/homes/educationBg'

            } else if (this.classificationNameA == '职称申报') {
                url = '/homes/professional'

            } else if (this.classificationNameA == '其他培训') {
                url = '/homes/otherTraining'

            } else if (this.classificationNameA == '深户办理') {
                url = '/homes/applyHouseholdAccount'

            } else if (this.classificationNameA == '职业技能等级认定') {
                url = '/homes/vocationalSkillLevel'

            } else if (this.classificationNameA == '劳务信息') {
                url = '/homes/serviceInfo'

            } else if (this.classificationNameA == '公务员考试') {
                url = '/homes/civilServant'

            } else if (this.classificationNameA == '工业自动化人才培训') {
                url = '/homes/talentTraining'

            }

            this.toTop()
            this.$router.push({
                path: url,
                query: {
                    ids: 1,
                },
            })
        },
        //企业了解更多
        LearnMore2() {

            let url = ''

            if (this.classificationNameB == '高新企业认定申报') {
                url = '/homes/enterpriseService/highTechEnterprise'

            } else if (this.classificationNameB == '建筑工程资质申报') {
                url = '/homes/enterpriseService/constructionProject'

            } else if (this.classificationNameB == '职业技能等级评价机构申报') {
                url = '/homes/enterpriseService/vocationalSkill'

            } else if (this.classificationNameB == '安全培训') {
                url = '/homes/safetyTraining'

            } else if (this.classificationNameB == '产业工人培训') {
                url = '/homes/enterpriseService/workerTraining'

            } else if (this.classificationNameB == '企业服务体系认证') {
                url = '/homes/enterpriseService/systemCertification'

            } else if (this.classificationNameB == '企业补贴申报') {
                url = '/homes/enterpriseService/enterpriseSubsidy'

            } else if (this.classificationNameB == '场地租赁') {
                url = '/homes/enterpriseService/siteLease'

            } else if (this.classificationNameB == '劳务派遣') {
                url = '/homes/enterpriseService/laborDispatch'

            }

            this.toTop()
            this.$router.push({
                path: url,
                query: {
                    ids: 10,
                },
            })
        },
        handleScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            if (this.scrollTop > 110) {
                this.isFiexd = true
            } else {
                this.isFiexd = false
            }
            if (this.scrollTop > 500) {
                //设置页面滑动多少显示按钮
                this.goTopShow = true
            } else {
                this.goTopShow = false
            }
        },
        //回到顶部
        toTop() {
            let timer = null,
                _that = this
            cancelAnimationFrame(timer)
            timer = requestAnimationFrame(function fn() {
                if (_that.scrollTop > 0) {
                    _that.scrollTop -= 100 //回到顶部的速度
                    document.body.scrollTop = document.documentElement.scrollTop =
                        _that.scrollTop
                    timer = requestAnimationFrame(fn)
                } else {
                    cancelAnimationFrame(timer)
                    _that.goTopShow = false
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.home_index {
    .personalService {
        background: #fafafa;
        padding-top: 98px;
    }

    .personalServiceActive {
        color: #ff881e !important;
    }

    .service_div {
        padding-bottom: 50px;

        .title {
            div {
                font-size: 40px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 38px;
            }

            .orange_div {
                color: #ff881e;
            }

            .black_div {
                color: #333333;
            }
        }

        .serviceClassification {
            margin-top: 76px;

            ul {
                padding-bottom: 8px;
                border-bottom: 1px solid #dddddd;
                margin-bottom: 20px;

                .list {
                    width: 100px;
                    position: relative;

                    .icon_img {
                        width: 70px;
                        height: 70px;
                    }

                    .name {
                        width: 110px;
                        height: 42px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        text-align: center;
                        margin-top: 8px;
                        line-height: 24px;
                    }

                    .line {
                        width: 100px;
                        height: 3px;
                        background: #ff881e;
                        border-radius: 1px;
                        position: absolute;
                        left: 0;
                        bottom: -9px;
                    }
                }
            }
        }

        .main {
            height: 420px;
            overflow: hidden;
            overflow-y: scroll;
            scrollbar-width: none;
            /* firefox */
            -ms-overflow-style: none;
        }

        .main::-webkit-scrollbar {
            width: 0;
        }
    }

    .module2 {
        background: white;
        padding-bottom: 50px;
    }

    .module8S_div {
        margin-top: -430px;
    }

    .LearnMoreBtn {
        width: 379px;
        height: 46px;
        background: #ff881e;
        border-radius: 8px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
        margin-top: 30px;
    }
}
</style>
