<template>
	<div class="module_div">
		<div class="content module3">
			<ul class="flex display">
				<li
					@click="btn(index)"
					class="list display column"
					v-for="(item, index) in homeData"
					:key="index"
				>
					<img
						class="icon_img"
						:src="
							item.skillTrainingImgUrl
								? item.skillTrainingImgUrl
								: item.categoryEntryImgUrl
						"
						alt=""
					/>
					<div class="name">
						{{
							item.skillTrainingName
								? item.skillTrainingName
								: item.categoryEntryName
						}}
					</div>
					<div class="text">
						{{
							item.skillTrainingIntroduce
								? item.skillTrainingIntroduce
								: item.categoryEntryIntroduce
						}}
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
		activeIndexNum: {},
	},
	data() {
		return {
			list: [{}, {}, {}],
		}
	},
	methods: {
		btn(index) {
			if (this.activeIndexNum == 2) {
				let url = ''
				if (index == 0) {
					url = '/homes/constructionSite'
				} else if (index == 1) {
					url = '/homes/constructionEngineering'
				} else if (index == 2) {
					url = '/homes/adornmentDecorated'
				}
				this.$router.push({
					path: url,
					query: {
						ids: 1,
					},
				})
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.module_div {
	padding-top: 14px;
	.module3 {
		ul {
			.list:nth-child(1) {
				margin-left: 0 !important;
			}
			.list {
				width: 380px;
				margin-left: 30px;
				img {
					width: 380px;
					height: 210px;
				}
				.name {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					margin-top: 20px;
					line-height: 20px;
				}
				.text {
					width: 336px;
					height: 64px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
					text-align: center;
					margin-top: 13px;
				}
			}
		}
	}
}
</style>