<template>
	<div class="module_main">
		<div class="classification_div display">
			<div
				class="classification_list display pointer"
				:class="activeIndex == index ? 'active' : ''"
				v-for="(item, index) in dataList"
				:key="index"
				@click="changeBtn(item, index)"
			>
				{{ item.name }}
			</div>
		</div>
		<div class="introduce" v-if="introduce == true">
			{{ introduceData }}
		</div>
		<div class="module8">
			<div class="carousel_box">
				<el-carousel :interval="5000" :autoplay="false">
					<el-carousel-item v-for="(res, index) in imgList" :key="index">
						<div class="serve_customer flex">
							<div class="list" v-for="(item, index2) in res" :key="index2">
								<img class="swiper_img" :src="item" alt="" />
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		introduce: {},
		dataList: {},
		imgList: {},
		introduceData: {},
	},
	data() {
		return {
			activeIndex: 0,
		}
	},
	mounted() {},
	methods: {
		changeBtn(item, index) {
			this.activeIndex = index
			this.introduceData = item.introduce
			this.imgList = item.images
		},
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	// padding-top: 40px;
	.classification_div {
		.active {
			background: #003c7e !important;
			color: white !important;
		}
		.classification_list {
			height: 46px;
			border: 1px solid #dddddd;
			border-radius: 23px;
			padding: 0px 32px;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin: 0px 11px;
		}
	}
	.module8 {
		width: 1400px;
		margin: 0 auto;
		padding-bottom: 100px;
		.carousel_box {
			margin-top: 30px;
			::v-deep.swiper-container {
				height: 246px;
				padding-left: 3px;
			}

			::v-deep.el-carousel__container {
				height: 270px;
			}
			::v-deep.el-carousel {
				.el-carousel__item {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.el-carousel__container {
					.el-carousel__arrow {
						width: 48px;
						height: 48px;
						font-size: 24px;
						background: rgba(51, 51, 51, 0.5);
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.list:nth-child(1) {
				margin-left: 0 !important;
			}
			.list {
				width: 380px;
				height: 260px;
				margin-left: 30px;
				.swiper_img {
					width: 380px;
					height: 260px;
				}
			}
		}
	}
	.introduce {
		width: 759px;
		height: 40px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 24px;
		margin: 0 auto;
		margin-top: 25px;
		text-align: center;
	}
}
</style>