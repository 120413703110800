<template>
	<div class="module_main">
		<div class="content module6">
			<div class="classification display">
				<div
					class="classification_list display pointer"
					:class="activeIndex == index ? 'active' : ''"
					v-for="(item, index) in classificationList"
					:key="index"
					@click="changeBtn(index)"
				>
					{{ item.name }}
					<div class="line"></div>
				</div>
			</div>
			<div class="main_div" v-if="activeIndex == 0">
				<ul class="display">
					<li v-for="(item, index) in homeData" :key="index">
						<img :src="item.homeIsoThreeCertificationImgUrl" alt="" />
						<div class="name">{{ item.isoThreeCertificationName }}</div>
					</li>
				</ul>
			</div>
			<div class="hotSystem" v-else>
				<ul class="display">
					<li v-for="(item, index) in homeDataS" :key="index">
						<img :src="item.homeImgUrl" alt="" />
						<div class="name">{{ item.popularName }}</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
		homeDataS: {},
	},
	data() {
		return {
			activeIndex: 0,
			classificationList: [
				{
					name: 'ISO三体系认证',
				},
				{
					name: '更多热门体系认证',
				},
			],
			list: [{}, {}, {}],
		}
	},
	created() {},

	methods: {
		changeBtn(index) {
			this.activeIndex = index
		},
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	padding-top: 20px;
	.module6 {
		.classification {
			.active {
				font-weight: bold;
				color: #003c7e !important;
			}
			.classification_list:last-child {
				.line {
					display: none;
				}
			}
			.classification_list {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 24px;
				.line {
					width: 1px;
					height: 17px;
					background: #d6d6d6;
					margin: 0px 26px;
				}
			}
		}
		.main_div {
			margin-top: 30px;
			ul {
				li:nth-child(1) {
					margin-left: 0 !important;
				}
				li {
					margin-left: 28px;
					img {
						width: 380px;
						height: 210px;
					}
					.name {
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 20px;
						margin-top: 24px;
					}
				}
			}
		}
		.hotSystem {
			margin-top: 30px;
			ul {
				li:nth-child(1) {
					margin-left: 0 !important;
				}
				li {
					margin-left: 26px;
					img {
						width: 280px;
						height: 210px;
					}
					.name {
						width: 240px;
						height: 45px;
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 24px;
					}
				}
			}
		}
	}
}
</style>