<template>
	<div class="module_main">
		<div class="content module4">
			<ul class="flex">
				<li
					class="flex column"
					v-for="(item, index) in homeData"
					:key="index"
					@click="btn(index)"
				>
					<div class="name">{{ item.safetyTrainingName }}</div>
					<div class="text">{{ item.safetyTrainingIntroduce }}111</div>
					<img :src="item.safetyTrainingImgUrl" alt="" />
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
	},
	data() {
		return {}
	},
	methods: {
		btn(index) {
			console.log(index)
			let url = ''
			if (index == 0) {
				url = '/homes/safetyTraining'
			} else if (index == 1) {
				url = '/homes/enterpriseService/safetyOfficer'
			}
			this.$router.push({
				path: url,
				query: {
					ids: 10,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	padding-top: 18px;
	.module4 {
		ul {
			li {
				width: 540px;
				margin: 0px 30px;
				.name {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 24px;
				}
				.text {
					width: 448px;
					height: 38px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
					margin: 17px 0;
				}
				img {
					width: 540px;
					height: 250px;
				}
			}
		}
	}
}
</style>