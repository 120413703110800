<template>
    <div class="module_div">
        <div class="content module5">
            <ul class="flex display">
                <li class="list flex column" v-for="(item, index) in homeData" :key="index">
                    <div class="name nowrap">
                        NO.{{ index + 1 }}{{ item.salutationWayGetName }}
                    </div>
                    <div class="line"></div>
                    <div class="text">
                        {{ item.salutationWayGetIntroduce }}
                    </div>
                    <div class="learnMore pointer" @click="service">了解更多>></div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        homeData: {},
    },
    data() {
        return {
            list: [{}, {}, {}, {}],
        }
    },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.module_div {
    padding-top: 14px;

    .module5 {
        ul {
            .list:nth-child(1) {
                margin-left: 0 !important;
            }

            .list:hover {
                background: #003c7e !important;

                .name,
                .text {
                    color: white !important;
                }

                .learnMore {
                    display: block !important;
                }

                .line {
                    background: #fff !important;
                }
            }

            .list {
                width: 259px;
                height: 300px;
                padding: 50px 26px 0px 23px;
                box-sizing: border-box;
                position: relative;
                margin-left: 54px;

                .name {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 20px;
                }

                .line {
                    width: 30px;
                    height: 4px;
                    background: #333333;
                    margin: 25px 0;
                }

                .text {
                    width: 208px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 24px;
                }

                .learnMore {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ff881e;
                    position: absolute;
                    left: 24px;
                    bottom: 34px;
                    display: none;
                }
            }
        }
    }
}</style>