<template>
    <div class="module_div">
        <div class="content module5">
            <div class="courseOverview">
                <ul class="flex-center">
                    <li :style="{ backgroundImage: `url(${item.courseOverviewImgUrl})` }"
                        class="courseOverview_li flex-center column" v-for="(item, index) in homeData" :key="index">
                        <div class="courseOverview_li_title">
                            {{ item.courseOverviewName }}
                        </div>
                        <div class="courseOverview_li_line"></div>
                        <div class="courseOverview_li_content">
                            {{ item.courseOverviewIntroduce }}
                        </div>
                        <div class="btn pointer" @click="service">了解更多>></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        homeData: {},
    },
    data() {
        return {
            list: [
                {
                    title: '人力资源培训',
                    text: '12天掌握12个典型职位实训+通关',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '教师资格证',
                    text: '考前培训班、VIP协议班，快速拿证',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '金融资格证',
                    text: '金融行业四大从业资格证书，入行必备',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '金融职称证',
                    text: '企事业部门、金融机构认可，含金量高',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '中级职称证书（计算机类）',
                    text: '入深户首选，中级职称搭配学历核准入户',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
            ],
        }
    },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.module_div {
    padding-top: 14px;

    .module5 {
        .courseOverview {
            ul {
                height: 326px;

                .courseOverview_li:hover {
                    width: 260px;
                    height: 326px;
                    transition: all 0.6s;

                    .btn {
                        display: block;
                    }
                }

                .courseOverview_li {
                    width: 240px;
                    height: 300px;
                    background: #0053ae;
                    transition: all 0.6s;

                    .courseOverview_li_title {
                        width: 144px;
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 28px;
                        margin-top: 65px;
                    }

                    .courseOverview_li_line {
                        width: 39px;
                        height: 4px;
                        background: #ff881e;
                        margin: 24px 0;
                    }

                    .courseOverview_li_content {
                        width: 162px;
                        height: 42px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 24px;
                        text-align: center;
                    }

                    .btn {
                        width: 120px;
                        height: 45px;
                        background: #ff881e;
                        border-radius: 5px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 30px;
                        display: none;
                        line-height: 45px;
                        text-align: center;
                    }
                }
            }
        }
    }
}</style>