<template>
    <div class="module_div">
        <div class="content module9 Around flex-wrap">
            <div class="list flex-center column" v-for="(item, index) in list" :key="index">
                <img :src="item.automationRevolutionImgUrl" alt="">
                <div class="listName">{{ item.automationRevolutionName }}</div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {},
    },
    data() {
        return {}
    },
    mounted() { },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.module_div {
    .module9 {
        .list {
            width: 340px;
            margin-bottom: 12px;

            img {
                width: 340px;
                height: 160px;

            }

            .listName {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-top: 14px;
            }


        }
    }

}
</style>