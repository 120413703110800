<template>
	<div class="module_div">
		<div class="content module1">
			<el-carousel indicator-position="outside" :autoplay="false">
				<el-carousel-item v-for="(item, index) in homeData" :key="index">
					<img
						class="icon_img"
						:src="
							item.specialOperationImgUrl
								? item.specialOperationImgUrl
								: item.skillLevelImgUrl
						"
						alt=""
					/>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		activeIndex: {},
		homeData: {},
	},
	data() {
		return {}
	},
}
</script>
<style lang="scss" scoped>
.module_div {
	padding-top: 16px;
	.module1 {
		.icon_img {
			width: 1000px;
			height: 320px;
		}
		.minImg {
			width: 896px;
			height: 318px;
		}
		::v-deep.el-carousel__container {
			height: 320px !important;
		}
		::v-deep.el-carousel__item.is-animating {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		::v-deep.el-carousel__indicator--horizontal {
			width: 12px;
			height: 12px;
			background: #dddddd;
			border-radius: 50%;
			padding: 0 !important;
			margin: 0px 12px;
			margin-top: 10px;
		}
		::v-deep.el-carousel__button {
			width: 12px;
			height: 12px;
			background: #dddddd;
			border-radius: 50%;
		}
		::v-deep.el-carousel__indicators--outside button {
			background: #003c7e;
		}
	}
}
</style>