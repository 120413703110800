<template>
    <div class="module_div">
        <div class="content module8 Between">
            <div class="list flex-center column" v-for="(item, index) in list" :key="index">
                <img :src="item.mobileExaminationRequirementImgUrl" alt="">
                <div class="listName">{{ item.examinationRequirementName }}</div>
                <div class="listText">{{ item.examinationRequirementIntroduce }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {},
    },
    data() {
        return {}
    },
    mounted() { },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.module_div {
    padding-top: 14px;

    .module8 {
        .list {
            width: 278px;
            height: 320px;
            background: #F4F5F5;
            border-radius: 5px;
            padding-top: 39px;

            img {
                width: 98px;
                height: 98px;

            }

            .listName {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
                margin: 26px 0;
            }

            .listText {
                width: 248px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
}
</style>