<template>
	<div class="module_main">
		<div class="content module3">
			<ul class="flex">
				<li
					class="flex-center column"
					v-for="(item, index) in homeData"
					:key="index"
				>
					<img :src="item.homeWhyEvaluationOrganizationIconUrl" alt="" />
					<div class="name">{{ item.whyEvaluationOrganizationName }}</div>
					<div class="text">
						{{ item.whyEvaluationOrganizationIntroduce }}
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
	},
	data() {
		return {
			list: [{}, {}, {}, {}],
		}
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	padding-top: 18px;
	.module3 {
		ul {
			padding-left: 4px;
			li:nth-child(1) {
				margin-left: 0 !important;
			}
			li {
				width: 276px;
				height: 336px;
				box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
				margin-left: 22px;
				img {
					width: 100px;
					height: 100px;
					margin-top: 39px;
				}
				.name {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 20px;
					margin-top: 28px;
					margin-bottom: 22px;
				}
				.text {
					width: 225px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
				}
			}
		}
	}
}
</style>