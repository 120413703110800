<template>
	<div class="module_div">
		<div class="content module4 display">
			<div
				class="list_div flex-center column"
				v-for="(item, index) in homeData"
				:key="index"
			>
				<img class="list_img" :src="item.educationFourWaysImgUrl" alt="" />
				<div class="title">{{ item.educationFourWaysName }}</div>
				<div class="cont">
					<div
						class="cont_list"
						v-for="(items, indexs) in item.educationWhyIntroduce"
						:key="indexs"
					>
						{{ items }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		homeData: {},
	},
	data() {
		return {
			list: [{}, {}, {}, {}],
		}
	},
}
</script>
<style lang="scss" scoped>
.module_div {
	padding-top: 5px;
	.module4 {
		.list_div {
			width: 220px;
			height: 400px;
			box-sizing: border-box;
			margin-left: 86px;
			.list_img {
				width: 80px;
				height: 80px;
				background: 50%;
			}
			.title {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #27b07c;
				margin-top: 20px;
				line-height: 24px;
			}
			.cont {
				margin-top: 14px;
				.cont_list {
					width: 220px;
					height: 44px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					border-bottom: 1px dashed #dddddd;
					line-height: 44px;
				}
			}
		}
		.list_div:nth-child(1) {
			margin-left: 0 !important;
		}
		.list_div:nth-child(2) {
			.title {
				color: #16b3e6;
			}
			.consulting {
				color: #16b3e6;
				border: 1px solid #16b3e6;
			}
		}
		.list_div:nth-child(3) {
			.title {
				color: #ec7c0e;
			}
			.consulting {
				color: #ec7c0e;
				border: 1px solid #ec7c0e;
			}
		}
		.list_div:nth-child(4) {
			.title {
				color: #e73f3f;
			}
			.consulting {
				color: #e73f3f;
				border: 1px solid #e73f3f;
			}
		}
	}
}
</style>